import React, {useEffect, useState} from 'react';
import '../css/PhaseDisplayer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock ,faEye} from '@fortawesome/free-solid-svg-icons';
/* global tf_planNonce */

const PhaseDisplayer = ({getPhase, phases, phasesDates}) => {
    const [userLang, setUserLang] = useState(navigator.language);
    const [phasesData, setPhasesData] = useState([]);
    const [launched, setLaunched] = useState(false)

    const translations = {
        en: {
            startDateTextNotStarted: (<>Start scheduled for : </>),
            startDateTextStarted: (<>Started on : </>),
            endDateTextStarted: (<>Finished on : </>),
            dayFrom: 'Day'
        },
        fr: {
            startDateTextNotStarted: (<>Début prévu le : </>),
            startDateTextStarted: (<>Débuté le : </>),
            endDateTextStarted: (<>Terminé le : </>),
            dayFrom: 'Jour'

        },
        es: {
            startDateTextNotStarted: (<>Inicio programado para : </>),
            startDateTextStarted: (<>Comenzó el : </>),
            endDateTextStarted: (<>Terminado el : </>),
            dayFrom: 'Día'
        }
    };

    const handle_startPhase = async (index) => {
        console.log(index);
        const url = 'https://m67.tech/wp-json/tabacfinisher/v1/startPlan/';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_planNonce.nonce // Assurez-vous que le nonce est correctement configuré
            },
            body: JSON.stringify({actualPhase: index + 1}),
            credentials: 'include' // pour envoyer les cookies de session si nécessaire
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Response:', data);
            setLaunched(true);
            getPhase();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    function daysSince(dateString) {
        // Créer une date à partir de la chaîne
        const pastDate = new Date(dateString);
        const currentDate = new Date();

        // Calculer la différence en millisecondes
        const difference = currentDate - pastDate;

        // Convertir la différence en jours
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        console.log(dateString)
        console.log(days)
        return days;
    }

    return <>
        {phases.length > 0 && phases.map((phase, index) => (
            <div className='phaseContainer'>
                <div className='div-style'>{phase['Phase Number']}</div>
                <div className='phaseCartouche'>

                        <div className='dayFromContainer'>
                              <div className='dayFromTilte'>
                                  {index+1===parseInt(phasesDates[0].actualPhase, 10)  &&  (translations[userLang.slice(0, 2)] || translations["en"]).dayFrom}
                        </div>
                        <div className='dayFromContent'>
                            {index+1===parseInt(phasesDates[0].actualPhase, 10)  &&  daysSince(phasesDates[0].EffectiveStart) + 1}
                        </div>
                    </div>
                    <div className='phaseTitleName'>
                        {phase['Phase Name']}
                        {phasesDates && phasesDates[0] && phasesDates[0].plannedStart && (
                            <div className='phaseActualDay'>
                            <span className='labelText'>
                            {!phasesDates[0].EffectiveStart && index===0 && (translations[userLang.slice(0, 2)] || translations["en"]).startDateTextNotStarted}
                                {
                                    phasesDates[0].EffectiveStart &&
                                    index + 1 <= phasesDates[0].actualPhase &&
                                    !phase['end_date'] ?
                                        ((translations[userLang.slice(0, 2)] || translations["en"]).startDateTextStarted) :
                                        (index + 1 > phasesDates[0].actualPhase ? null : ((translations[userLang.slice(0, 2)] || translations["en"]).endDateTextStarted))
                                }
                            </span>

                                {!phasesDates[0].EffectiveStart && index===0 && phasesDates[0].plannedStart}
                                {phasesDates[0].EffectiveStart &&  index + 1 <= phasesDates[0].actualPhase && !phase['end_date']?phasesDates[0].EffectiveStart :
                                    (index + 1 > phasesDates[0].actualPhase ?null:phase['end_date'])}
                            </div>
                        )}
                    </div>
                    {/*btn go pour la phase 1 avant le lancement de la premiere phase (plan non demarré)*/}
                    {!phasesDates[0].EffectiveStart && index === 0 && !launched &&
                        <button className='btnPhaseGO' onClick={(e) => handle_startPhase(index)}>GO!</button>}
                    {/*btn go pour les phases apres le lancement de la premiere phase (plan  demarré)*/}
                    {phasesDates[0].EffectiveStart && index !== 0 && index === parseInt(phasesDates[0].actualPhase, 10) &&
                        <button className='btnPhaseGO' key={'btn' + index}
                                onClick={(e) => handle_startPhase(index)}>GO!</button>}
                    {/*btn coche verte pour phase terminé*/}
                    {phasesDates[0].EffectiveStart && phase['end_date'] &&
                        <button className='btnPhaseCompleted' onClick={(e) => handle_startPhase(index)}></button>}
                    {/*btn orange pour phase en cours*/}
                    {phasesDates[0].EffectiveStart && phase['end_date'] === null && index + 1 === parseInt(phasesDates[0].actualPhase, 10) &&
                        <button className='btnPhaseLive' onClick={(e) => handle_startPhase(index)}> <FontAwesomeIcon icon={faEye } size="2x"  /></button>}
                    {phasesDates[0].EffectiveStart  && index  >= parseInt(phasesDates[0].actualPhase, 10)+1 &&
                        <button className='btnPhaseGO' onClick={(e) => handle_startPhase(index)}>    <FontAwesomeIcon icon={faLock}  size="2x" /></button>}
                </div>


            </div>
        ))
        }

    </>

}

export default PhaseDisplayer;