import React from 'react';
import '../css/VideoPlayer.scss';
const VideoPlayer = ({ src, width = "640", height = "360" }) => {
    return (
        <div >
            <video  className='player' width={width} height={height}  autoPlay>
                <source src={src} type="video/mp4" />
                Votre navigateur ne supporte pas la balise vidéo.
            </video>
        </div>
    );
};

export default VideoPlayer;