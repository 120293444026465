import React, {useEffect, useState} from 'react';
import '../css/ThemeDisplayer.scss';


/* global tf_planNonce */


function ThemeDisplayer({theme, handle_getQuestionForATheme,themeResponsesCount,index}) {
    const backgroundStyle = {
        background: `linear-gradient(to right, #${theme['color']} , #15f5ba)`
    };


    return<>
    <div className='themeContainer' style={backgroundStyle} onClick={()=>handle_getQuestionForATheme(theme)}>

        {themeResponsesCount.length>0 && themeResponsesCount[index].count==='12' && <div className='iconeQuestionTheme'><img src='https://m67.tech/wp-content/uploads/2024/04/cocheVert2.png' className='imgCocheQuestion'/> </div>}
        {themeResponsesCount.length>0 && themeResponsesCount[index].count!=='12' && <div className='iconeQuestionThemeEmpty'> </div>}
        <div className='nameQuestionTheme'>{theme['theme']}</div>
            <div className='PercentQuestionTheme'>{themeResponsesCount.length>0 && themeResponsesCount[index].count}/12</div>
        </div>
    </>
        }
        export default ThemeDisplayer;