import React, {useEffect, useState} from 'react';
import '../css/QuestionsDisplayer.scss';
import QuestionsV2 from "./QuestionsV2";


function QuestionsDisplayer({userID, questions, userResponses, theme, setNewResponse}) {
    const backgroundStyle = {
        background: `linear-gradient(to right, #${theme['color']} , #15f5ba)`
    };
    const [indexQuestion, setIndexQuestion] = useState(null);
    const [question, setQuestion] = useState(null);
    const [userLang, setUserLang] = useState(navigator.language);
    const translations = {
        en: {
            btnQuestionBack: 'Back',
            btnQuestionValid: 'Valid',
            btnQuestionNext: 'Next'
        },
        fr: {
            btnQuestionBack: 'Retour',
            btnQuestionValid: 'Valider',
            btnQuestionNext: 'Suivant'
        },
        es: {
            btnQuestionBack: 'Atrás',
            btnQuestionValid: 'Validar',
            btnQuestionNext: 'Próximo'
        }
    };

    function handleQuestionClick(question, index){
        setQuestion(question);
        setIndexQuestion(index);
    }

    useEffect(() => {
console.log(question);
    }, [question]);
    const handle_btnRetour = () => {

        window.location.href = 'https://m67.tech/plan';
    }
    return (
        <>
            {!question && questions.length > 0 &&
                <><div className='questionChooserContainer'>
                    {questions.map((question, index) => (
                        <div key={index} onClick={() => handleQuestionClick(question, index)}
                             className='questionChooserElement' style={backgroundStyle}>
                            <div className='questionNumberElement'> {index + 1}</div>
                            {userResponses.some(response => response.questionID === question.Id) &&
                                <div className='questionWithResponse'></div>
                            }
                        </div>))}
                     </div>
                <button className='btnQuestionBack'
                onClick={() => handle_btnRetour()}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnQuestionBack}</button>
</>
}
            {question &&
                <QuestionsV2 setNewResponse={setNewResponse} theme={theme} setQuestion={setQuestion} question={question}
                             userResponses={userResponses} userID={userID} number={indexQuestion}/> }



        </>

    )
}

export default QuestionsDisplayer;