import React, {useEffect, useState} from 'react';
import '../css/Temporality.scss';


/* global tf_planNonce */


function Temporality({theme, themeResponsesCount, index,setPhases}) {
    const translations = {
        en: {
            planDuration: (<>How many weeks do you want to stop?</>),
            planStartDate: (<>When do you plan to start?</>),
            btnGenerate: 'Create my plan',
            durationEmpty: 'Please indicate the duration of the plan.'
        },
        fr: {
            planDuration: (<>En combien de semaines désirez-vous arrêter?</>),
            planStartDate: (<>Quand pensez-vous débuter?</>),
            btnGenerate: 'Créer mon plan',
            durationEmpty: "Merci d'indiquer la durée  du plan."
        },
        es: {
            planDuration: (<>¿Cuántas semanas quieres parar?</>),
            planStartDate: (<>¿Cuándo piensas empezar?</>),
            btnGenerate: 'Crear mi plan',
            durationEmpty: 'Por favor indique la duración del plan.'
        }
    };

    const backgroundStyle = {
        background: `linear-gradient(to right, #836fff , #15f5ba)`
    };
    const [userLang, setUserLang] = useState(navigator.language);
    const [responseCount, setResponseCount] = useState(0);
    const [planDuration, setPlanDuration] = useState(0);
    const [planStartDate, setPlanStartDate] = useState(null);
    const [mode, setMode] = useState();

    const handle_startDateChange = (startDate) => {
        setPlanStartDate(startDate)
    }
    const handle_durationChange = (weeks) => {
        setPlanDuration(weeks)
    }

    const handle_btn_create_plan = () => {
        if (planDuration <= 0) {
            alert((translations[userLang.slice(0, 2)] || translations["en"]).durationEmpty);
        } else {
            //todo sauvegarder en db planstartDate et planDuration
            if (planStartDate) {
                sendPlanData(planDuration, planStartDate)
            } else {
                sendPlanData(planDuration)
            }
        }
    }
    const sendPlanData = async (plannedDuration, plannedStart = null) => {
        const url = 'https://m67.tech/wp-json/tabac_finisher/v1/createPlan';
        const planData = {
            planDuration: plannedDuration, // exemple de durée du plan, en mois
            plannedStart: (plannedStart ? plannedStart : null) // exemple de date de début planifiée, optionnelle
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': tf_planNonce.nonce // Assurez-vous que le nonce est correctement configuré
                },
                body: JSON.stringify(planData),
                credentials: 'include' // Nécessaire pour les cookies d'authentification si l'API est utilisée sur le même domaine
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Success:', responseData);
            const regex = /```json\n([\s\S]*?)\n```/;
            let matches = regex.exec(responseData.message);
            if(!matches){
                const regex2 = /\[(.*)\]/;
                matches = regex2.exec(responseData.message);
            }
            setPhases(responseData.message);
            if (matches && matches[1]) {
                const jsonPart = matches[1].trim();  // Utilisez trim pour enlever les espaces blancs avant et après
                try {
                    console.log('JSON Part:', jsonPart);
                    const jsonData = JSON.parse(jsonPart);
                    console.log('Extracted JSON:', jsonData);
                    setPhases(jsonData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else {
                console.log('No JSON found in the message');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };


    //empeche de saisir une date antérieure a la date actuelle
    useEffect(() => {
        const today = new Date();

        // Formater la date en YYYY-MM-DD
        const dateStr = today.toISOString().split('T')[0];

        // Définir l'attribut min de l'input de type date
        document.getElementById('dateInput').min = dateStr;
    }, []);
    return <>
        <div className='temporalityContainer' style={backgroundStyle}
        >
            <span
                className='planDurationLabel'> {(translations[userLang.slice(0, 2)] || translations["en"]).planDuration}</span>
            <input type='number' min='1' max='52' className='planDurationInput' onChange={(e) => {
                handle_durationChange(e.target.value)
            }}/>
            <hr/>
            <span
                className='planDurationLabel'> {(translations[userLang.slice(0, 2)] || translations["en"]).planStartDate}</span>
            <input type='date' id='dateInput' className='planDurationInput' onChange={(e) => {
                handle_startDateChange(e.target.value)
            }}/>
            <button className='btnGenerate' onClick={handle_btn_create_plan}
            >{(translations[userLang.slice(0, 2)] || translations["en"]).btnGenerate}</button>
        </div>
    </>
}

export default Temporality;