import React, {useEffect, useState} from 'react';
import '../css/PlanPaiement.scss';


/* global tf_planNonce */


function PlanPaiement({userID}) {

    const [userLang, setUserLang] = useState(navigator.language);
    const translations = {
        en: {
            avantage1: (<>- Interval reduced to 15 days for tests (instead of 30 days).</>),
            avantage2: (<>- 1 Analysis offered every 15 days.</>),
            avantage3: (<>- 1 Personalized and detailed plan to put an end to tobacco for good. </>),
            avantage4: '',
            buyPalnBtn: 'Change your life'
        },
        fr: {
            avantage1: (<>- Intervale réduit à 15 jours pour les tests (au lieu de 30 jours).</>),
            avantage2: (<>- 1 Analyse offerte chaques 15 jours.</>),
            avantage3: (<>- 1 Plan personnalisé et détaillé pour en finir définitivement avec le tabac.</>),
            avantage4: '',
            buyPalnBtn: 'Changez de vie'
        },
        es: {
            avantage1: (<>- Intervalo reducido a 15 días para las pruebas (en lugar de 30 días).</>),
            avantage2: (<>- 1 Análisis ofrecido cada 15 días.</>),
            avantage3: (<>- 1 Plan personalizado y detallado para acabar definitivamente con el tabaco.</>),
            avantage4: '',
            buyPalnBtn: 'Cambia tu vida'
        }
    };

    return (
        <>
            <div className='planBuyContainer'>
                <div className='div-avantage'>
                {(translations[userLang.slice(0, 2)] || translations["en"]).avantage1}<br/>
                {(translations[userLang.slice(0, 2)] || translations["en"]).avantage2}<br/>
                {(translations[userLang.slice(0, 2)] || translations["en"]).avantage3}<br/>
                </div>
                <div className='divBuyPlanButton' >
                <div className='planBuyButtonContainer'>
                    <img className='planBuyLogoImage' alt='tabac finisher logo'
                         src='https://m67.tech/wp-content/uploads/2024/02/Logo-tabac-finishing.jpeg'/>
                    <div><span className='oldPricePlan'>12 euro</span><br/><span className='newPricePlan'>Prix de lancement: 8.40 euro</span></div>
                    <form action="/wp-json/tabac_finisher/v1/checkoutPlan" method="POST">
                        <input type="hidden" name="user" value={userID}/>
                        <button type="submit" className='btn-recharge btn-plan-buy'>
                            <div className='analyseReloadContainer'>

                        <span className='analyseReload1'>
                           {(translations[userLang.slice(0, 2)] || translations["en"]).buyPalnBtn}
                            </span>


                            </div>
                        </button>
                    </form>
                </div>
                </div>
            </div>
        </>
    )

}

export default PlanPaiement;
