import logo from './logo.svg';
import './App.css';
import React, {useEffect,useState} from "react";
import PlanPaiement from './components/PlanPaiement';
import VideoPlayer from "./components/VideoPlayer";
import QuestionTheme from "./components/QuestionTheme";

/* global tf_planNonce */
function App() {
    const [planBuy,setPlanBuy] = useState(false);
    const [planID,setPlanID] = useState(null);
    const [user,setUser] = useState(0);
    const [plannedStart,setPlannedStart] = useState(null);
    const [EffectiveStart,setEffectiveStart] = useState(null);

  useEffect(() => {
    fetch('/wp-json/tabac_finisher/v1/check-plan-buy', {
      method: 'GET', // Méthode HTTP utilisée pour la requête
      credentials: 'include', // Nécessaire pour inclure les cookies de session dans la requête
      headers: {
        'Content-Type': 'application/json',
        'X-WP-Nonce': tf_planNonce.nonce // Remplacez par votre nonce réel
      },
    })
        .then(response => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json(); // Convertir la réponse en JSON
        })
        .then(data => {
          console.log(data); // Traiter les données reçues
            setPlanBuy( data['planBuy'] === '0'?false:true)

            setUser(data['user']);
            if(data['planBuy']==='1') {
                setPlanID(data['planID']);
                setPlannedStart(data['plannedStart']);
                setEffectiveStart(data['EffectiveStart']);



            }
        })
        .catch(error => {
          console.error('Erreur:', error); // Gérer les erreurs éventuelles
        });
  }, []);


  return (
    <div className="App">

        {planBuy && !planID &&<QuestionTheme userID={user} />}
        {!planBuy && <VideoPlayer src='https://m67.tech/wp-content/uploads/2024/04/avantPlan_FR.mp4' />}
        {!planBuy && <PlanPaiement userID={user} />}

    </div>

  );
}

export default App;
