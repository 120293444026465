import React, {useEffect, useState} from 'react';
import '../css/QuestionTheme.scss';
import ThemeDisplayer from "./ThemeDisplayer";
import QuestionsV2 from "./QuestionsV2";
import QuestionsDisplayer from "./QuestionsDisplayer";
import PercentageCompletiondisplayer from "./PercentageCompletiondisplayer";
import Temporality from "./Temporality";
import PhaseDisplayer from "./PhaseDisplayer";


/* global tf_planNonce */


function QuestionTheme({userID}) {

    const [userLang, setUserLang] = useState(navigator.language);
    const [themes, setThemes] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [userResponses, setUserResponses] = useState([]);
    const [newResponse, setNewResponse] = useState(false);
    const [themeSelected, setThemeSelected] = useState('');
    const [themeResponsesCount, setThemeResponsesCount] = useState([]);
    const [responseCounter, setResponseCounter] = useState(0);
    const [displayTemporality, setDisplaytemporality] = useState(false);
    const [phases, setPhases] = useState([]);
    const [phasesDates, setPhasesDates] = useState([])

    const translations = {
        en: {
            btnGeneratePlan: (<>GENERATE MY PLAN</>),
        },
        fr: {
            btnGeneratePlan: (<>G&#201;N&#201;RER MON PLAN</>),
        },
        es: {
            btnGeneratePlan: (<>GENERAR MI PLAN</>),
        }
    };

    useEffect(() => {

    }, [userResponses, questions]);
    const getUserPhase = () =>{
        const url = `https://m67.tech/wp-json/tabacfinisher/v1/getUserPhases`;
        fetch(url, {
            method: 'GET', // La méthode HTTP
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_planNonce.nonce // Remplacez 'votre-nonce-ici' par le nonce généré
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('La requête a échoué');
                }
                return response.json();
            })
            .then(data => {
                setPhases(data['phases']);
                setPhasesDates(data['dates'])

            })
    }
    useEffect(() => {
        if(phases && !phases.length>0){
          getUserPhase();
console.log(phases);
        }
    }, [phases]);
    useEffect(() => {
        const language = navigator.language;
        if (themes.length === 0) {
// Définissez l'URL de votre API REST
            const url = `https://m67.tech/wp-json/tabacfinisher/v1/themeQuestions?language=${language}`;

            fetch(url, {
                method: 'GET', // La méthode HTTP
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': tf_planNonce.nonce // Remplacez 'votre-nonce-ici' par le nonce généré
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('La requête a échoué');
                    }
                    return response.json();
                })
                .then(data => {
                    getThemeResponsesCount();
                    setThemes(data);

                })
                .catch(error => {
                    console.error('Erreur:', error);
                });
        }
    }, [themes]);
    const getThemeResponsesCount = () => {
        const url = 'https://m67.tech/wp-json/tabacfinisher/v1/theme-counts';

// Effectuer la requête fetch
        fetch(url, {
            method: 'POST', // Méthode HTTP
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_planNonce.nonce // Assurez-vous de remplacer 'votre-nonce-ici' par le nonce réel
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('La requête réseau a échoué avec le statut ' + response.status);
                }
                return response.json(); // Parse la réponse JSON
            })
            .then(data => {
                console.log('Réponse reçue:', data);
                setThemeResponsesCount(data);
                const total = data.reduce((acc, item) => {
                    return acc + parseInt(item.count, 10);
                }, 0);
                setResponseCounter(total)
                // Traitez ici les données reçues, par exemple, afficher les résultats sur la page
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    };
    useEffect(() => {
        if (themeSelected !== '') {
            handle_getQuestionForATheme(themeSelected);
        }
    }, [newResponse]);
    const handle_getQuestionForATheme = (theme) => {
        setThemeSelected(theme)

        // URL de l'API
        let url = 'https://m67.tech/wp-json/tabac_finisher/v1/questions';

// Préparez les données à envoyer
        let data = {
            language: navigator.language, // Récupère la langue du navigateur
            theme: theme['theme']
        };

        fetch(url, {
            method: 'POST', // Utilisez la méthode POST pour envoyer des données
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_planNonce.nonce // Utilisez le nonce pour la sécurité
            },
            body: JSON.stringify(data) // Convertissez les données en chaîne JSON
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('La requête a échoué.');
                }
                return response.json(); // Parse la réponse JSON
            })
            .then(data => {
                console.log(data); // Traite les données reçues
                setQuestions(data['questions']);
                setUserResponses(data['userResponses'])
                setThemeSelected(theme);
                setNewResponse(false);
            })
            .catch(error => {
                console.error('Erreur:', error);
            });
    }
    const handle_generatePlan = () => {
        setDisplaytemporality(true);
    }
    return (
        <>
            {phases && phases.length>0 && <PhaseDisplayer getPhase={getUserPhase} phases={phases} phasesDates={phasesDates}/>}

            {((phases && !phases.length>0)||!phases) && <>
                {displayTemporality && <Temporality setPhases={setPhases}/>}
                {!displayTemporality && <>
                    <PercentageCompletiondisplayer count={responseCounter}/>
                    {responseCounter >= 25 && <div className='btnGENcontainer'>
                        <div className='generationBTNContainer'>
                            <button className='activeMenuPage btnGeneratePlan '
                                    onClick={handle_generatePlan}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnGeneratePlan}</button>
                        </div>
                    </div>
                    }

                    {questions && questions.length === 0 && themes.length > 0 && themes.map((theme, index) => (
                        <ThemeDisplayer key={index} index={index} themeResponsesCount={themeResponsesCount}
                                        handle_getQuestionForATheme={handle_getQuestionForATheme}
                                        setUserResponses={setUserResponses} setThemeSelected={setThemeSelected}
                                        theme={theme} setQuestions={setQuestions}/>

                    ))}
                    {questions && questions.length > 0 &&
                        <QuestionsDisplayer theme={themeSelected} setNewResponse={setNewResponse} questions={questions}
                                            userResponses={userResponses} userID={userID}/>}

                </>}
            </>}
        </>
    );
}

export default QuestionTheme;
