import React, {useEffect, useState} from 'react';
import '../css/QuestionTheme.scss';
import '../css/QuestionsV2.scss';


/* global tf_planNonce */


function QuestionsV2({userID, setQuestion, question, userResponses, theme, number, setNewResponse}) {
    const backgroundStyle = {
        background: `linear-gradient(to right, #${theme['color']} , #15f5ba)`
    };
    const [userResponse, setUserResponse] = useState('Exemple de réponse : ' + question.exemple);
    const [hasUserResponse, setHasUserResponse] = useState(false);

    const [userLang, setUserLang] = useState(navigator.language);
    const translations = {
        en: {
            btnQuestionBack: 'Back',
            btnQuestionValid: 'Valid',
            btnQuestionNext: 'Next'
        },
        fr: {
            btnQuestionBack: 'Retour',
            btnQuestionValid: 'Valider',
            btnQuestionNext: 'Suivant'
        },
        es: {
            btnQuestionBack: 'Atrás',
            btnQuestionValid: 'Validar',
            btnQuestionNext: 'Próximo'
        }
    };
    useEffect(() => {
        if (userResponses.some(response => response.questionID === question.Id)) {
            let index = userResponses.findIndex(response => response.questionID === question.Id);
            if (index !== -1) {
                setUserResponse(userResponses[index].ReponseToQuestion);
                setHasUserResponse(true);

            }
        }
    }, []);

    const handle_btnRetour = () => {
        setQuestion(null);

    }
    const handle_btnValid = (e) => {
        const url = 'https://m67.tech/wp-json/tabacfinisher/v1/user-plan-response';

// Les données à envoyer
        const data = {
            ReponseToQuestion: userResponse,
            questionID: question.Id,
            theme: theme['theme']
        };
        fetch(url, {
            method: 'POST', // Méthode HTTP
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': tf_planNonce.nonce // Utilisez le nonce pour la sécurité
            },
            body: JSON.stringify(data) // Convertir les données en chaîne JSON
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('La requête réseau a échoué');
                }
                return response.json(); // Parse la réponse JSON
            })
            .then(data => {
                console.log('Réponse reçue:', data);
                setQuestion(null);
                setNewResponse(true);
            })
            .catch(error => {
                console.error('Erreur:', error);
            });
    }
    return (
        <div>


            <div style={backgroundStyle} className='questionContainer'>

                <span className='titleQuestionV2'>Question<br/>n° {number + 1}  </span>
                <div className='questionNumberTitle'>{question.question}</div>
                <hr/>
                <span className='titleQuestionPourquoiV2'>Pourquoi cette question :</span>
                <div className='questionPourquoiTitle'>{question.interet}</div>
                <textarea placeholder={userResponse} rows="6"
                          className='questionTextarea' onChange={e => setUserResponse(e.target.value)}/>
                <div className='questionBtnContainer'>
                    <button className='btnQuestionBack'
                            onClick={() => handle_btnRetour()}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnQuestionBack}</button>
                    <button className='btnQuestionValid'
                            onClick={(e) => handle_btnValid(e)}>{(translations[userLang.slice(0, 2)] || translations["en"]).btnQuestionValid}</button>

                </div>
            </div>

        </div>
    );

}

export default QuestionsV2;