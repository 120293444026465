import React, {useEffect, useState} from 'react';
import '../css/PercentageCompletiondisplayer.scss';

function PercentageCompletiondisplayer({count}) {
    const backgroundStyle = {
        background: `linear-gradient(to right, #836fff , #15f5ba)`
    };
    const [userLang, setUserLang] = useState(navigator.language);
    const translations = {
        en: {
            percentMini: (<>25 required</>),
        },
        fr: {
            percentMini: (<>25 requis</>),
        },
        es: {
            percentMini: (<>25 requerido</>),
        }
    };
    return (
        <div className='responsePercentContainer'>
            <div style={backgroundStyle} className='responsePercentageDisplyerContainer'>
                <div className='percentContainer'>
                    <span>{count}/72</span>
                    <span
                        className='percentResponseLimit'>{(translations[userLang.slice(0, 2)] || translations["en"]).percentMini}</span>
                </div>
            </div>
        </div>
    )
}

export default PercentageCompletiondisplayer;
